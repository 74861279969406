<template>
  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card>
            <!--<v-card>-->
            <!--border-bottom: 3px solid #ccc;margin: 10px;-->
            <v-card-title style="" class="title">
              <v-icon>layers</v-icon> &nbsp; EDIT ITEM INFORMATION
              <v-spacer></v-spacer>
            </v-card-title>
            <!--</v-card>-->

            <div class="item_form_container">
              <div class="item_form">
                <v-form
                  ref="form"
                  @submit.prevent="save"
                  @keydown.native="form.errors.clear($event.target.name)"
                  @keyup.enter="save"
                  lazy-validation
                >
                  <v-container grid-list-md class="pa-0">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-text-field
                          v-model="form.name"
                          name="name"
                          label="Item Name"
                          class="pa-1"
                          :error-messages="form.errors.get('name')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>

                      <v-flex xs6>
                        <v-text-field
                          v-model="form.print_name"
                          name="print_name"
                          label="Print Name"
                          class="pa-1"
                          :error-messages="form.errors.get('print_name')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>

                      <v-flex xs6>
                        <v-text-field
                          v-model="form.item_code"
                          name="item_code"
                          label="Item Code"
                          class="pa-1"
                          :error-messages="form.errors.get('item_code')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="form.purchase_rate"
                          name="purchase_rate"
                          label="Purchase Rate"
                          class="pa-1"
                          :error-messages="form.errors.get('purchase_rate')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>

                      <v-flex xs4>
                        <v-text-field
                          v-model="form.sales_rate"
                          name="sales_rate"
                          label="Sales Rate"
                          class="pa-1"
                          :error-messages="form.errors.get('sales_rate')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>

                      <v-flex xs4>
                        <v-text-field
                          v-model="form.min_sales_rate"
                          name="min_sales_rate"
                          label="Min Sales Rate"
                          class="pa-1"
                          :error-messages="form.errors.get('min_sales_rate')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          v-model="form.mrp_sales"
                          name="mrp_sales"
                          label="MRP Sales"
                          class="pa-1"
                          :error-messages="form.errors.get('mrp_sales')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>

                      <v-flex xs6>
                        <v-autocomplete
                          v-model="form.category_id"
                          :items="items"
                          :search-input.sync="searchC"
                          hide-no-data
                          item-text="name"
                          item-value="id"
                          label="Category"
                          placeholder="Start typing to Search"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs6>
                        <v-checkbox
                          class="pa-1"
                          color="blue"
                          v-model="form.is_service_type"
                          label="Service Type"
                        >
                        </v-checkbox>
                      </v-flex>

                      <v-flex xs6>
                        <v-checkbox
                          class="pa-1"
                          color="blue"
                          v-model="form.is_taxable"
                          label="Taxable"
                        >
                        </v-checkbox>
                      </v-flex>

                      <v-flex xs12>
                        <p>Notification On</p>
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="form.notify.min_qty"
                          name="min_qty"
                          label="Min. Qunatity"
                          class="pa-1"
                          :error-messages="form.errors.get('min_qty')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="form.notify.max_qty"
                          name="max_qty"
                          label="Max Qunatity"
                          class="pa-1"
                          :error-messages="form.errors.get('max_qty')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="form.notify.reorder_level"
                          name="reorder_level"
                          label="Reorder Level"
                          class="pa-1"
                          :error-messages="form.errors.get('reorder_level')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </div>
              <div class="item_info">
                <v-form
                  ref="form"
                  @submit.prevent="save"
                  @keydown.native="form.errors.clear($event.target.name)"
                  @keyup.enter="save"
                  lazy-validation
                >
                  <v-container grid-list-md class="pa-0">
                    <v-layout row wrap>
                      <v-flex xs6>
                        <v-autocomplete
                          v-model="addItemData.warehouse_id"
                          :items="warehouses"
                          :search-input.sync="searchW"
                          hide-no-data
                          item-text="name"
                          item-value="id"
                          label="Ware House"
                          placeholder="Start typing to Search"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs6>
                        <v-autocomplete
                          v-model="addItemData.unit_id"
                          :items="units"
                          :search-input.sync="searchU"
                          hide-no-data
                          item-text="name"
                          item-value="id"
                          label="Unit"
                          placeholder="Start typing to Search"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          v-model="addItemData.rate"
                          name="rate"
                          label="Rate"
                          :error-messages="form.errors.get('rate')"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          v-model="addItemData.qty"
                          name="qty"
                          label="Qantity"
                          :error-messages="form.errors.get('qty')"
                          autocomplete="off"
                        />
                      </v-flex>

                      <v-btn
                        style="margin-top: 10px"
                        color="success"
                        outline
                        flat
                        @click="addItem(addItemData)"
                        :disabled="
                          !(
                            addItemData.warehouse_id &&
                            addItemData.rate &&
                            addItemData.qty &&
                            addItemData.unit_id
                          )
                        "
                      >
                        OK
                      </v-btn>

                      <v-flex xs12>
                        <v-data-table
                          :headers="headers"
                          :items="form.item_data"
                          hide-actions
                        >
                          <template slot="items" slot-scope="props">
                            <td>{{ ++props.index }}</td>
                            <td class="text-xs-left">
                              {{ props.item.warehouse_name }}
                            </td>
                            <td class="text-xs-left">{{ props.item.qty }}</td>
                            <td class="text-xs-left">{{ props.item.rate }}</td>
                            <td class="text-xs-left">
                              {{ props.item.unit_name }}
                            </td>
                            <td class="text-xs-left">
                              {{
                                parseFloat(props.item.qty) *
                                parseFloat(props.item.rate)
                              }}
                            </td>
                            <td class="text-xs-left">
                              <v-icon
                                small
                                @click="editItem(props.item, props.index)"
                                style="
                                  margin-right: 2px;
                                  background: blue;
                                  color: white;
                                "
                              >
                                edit
                              </v-icon>

                              <v-icon
                                small
                                @click="removeItem(props.item)"
                                style="background: red; color: white"
                                >clear
                              </v-icon>
                            </td>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </div>
            </div>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="warning"
                outlined
                text
                @click="(form.dialog = false), form.reset()"
                >Cancel
              </v-btn>
              <v-btn color="success" outlined text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-card-text>
              <v-form
                ref="form"
                @keydown.native="form.errors.clear($event.target.name)"
                @keyup.enter="save"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-autocomplete
                        v-model="addItemData.warehouse_id"
                        :items="warehouses"
                        :search-input.sync="searchW"
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        label="Ware House"
                        placeholder="Start typing to Search"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs6>
                      <v-autocomplete
                        v-model="addItemData.unit_id"
                        :items="units"
                        :search-input.sync="searchU"
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        label="Unit"
                        placeholder="Start typing to Search"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        v-model="addItemData.rate"
                        name="rate"
                        label="Rate"
                        :error-messages="form.errors.get('rate')"
                        autocomplete="off"
                      />
                    </v-flex>

                    <v-flex xs6>
                      <v-text-field
                        v-model="addItemData.qty"
                        name="qty"
                        label="Qantity"
                        :error-messages="form.errors.get('qty')"
                        autocomplete="off"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="success" outlined text @click="updateItem">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<!--<v-card-title style="width: 50%;border: 1px solid" class="itemRap">-->
<!--</v-card-title>-->


<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: "",
        print_name: "",
        item_code: "",
        category_id: "",
        is_service_type: 0,
        purchase_rate: "",
        sales_rate: "",
        mrp_sales: "",
        min_sales_rate: "",
        is_taxable: 0,
        item_data: [],
        notify: {
          min_qty: "",
          max_qty: "",
          reorder_level: "",
        },
      },
      "/api/account/inventory/item"
    ),
    searchAH: null,
    searchC: null,
    searchW: null,
    searchU: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    categorySearchData: [],
    wareHouseSearchData: [],
    unitsSearchData: [],
    accountHeads: [],
    addItemData: {
      id: "",
      warehouse_id: "",
      warehouse_name: "",
      qty: "",
      rate: "",
      unit_id: "",
      unit_name: "",
    },
    editIndex: "",
    dialog: false,
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      { text: "Ware House", value: "warehouse", align: "left", width: "30%" },
      {
        text: "Qty",
        value: "qry",
        align: "left",
        width: "20%",
        sortable: false,
      },
      {
        text: "Rate",
        value: "rate",
        align: "left",
        width: "20%",
        sortable: false,
      },
      { text: "Unit", value: "unit", sortable: false, align: "right" },
      { text: "Amount", value: "amount", sortable: false, align: "right" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "right",
        width: 120,
      },
    ],
  }),

  mounted() {
    console.log(this.$route.params.id);
    this.$rest
      .get("/api/account/inventory/item/" + this.$route.params.id)
      .then((res) => {
        this.form.id = res.data.id;
        this.form.category_id = res.data.category_id;
        this.form.name = res.data.name;
        this.form.item_code = res.data.item_code;
        this.form.print_name = res.data.print_name;
        this.form.sales_rate = res.data.sales_rate;
        this.form.purchase_rate = res.data.purchase_rate;
        this.form.min_sales_rate = res.data.min_sales_rate;
        this.form.mrp_sales = res.data.mrp_sales;
        this.form.is_service_type = res.data.is_service_type;
        this.form.is_taxable = res.data.is_taxable;
        this.form.notify.min_qty = res.data.notify
          ? res.data.notify.min_qty
          : "";
        this.form.notify.max_qty = res.data.notify
          ? res.data.notify.max_qty
          : "";
        this.form.notify.reorder_level = res.data.notify
          ? res.data.notify.reorder_level
          : "";
        this.form.item_data = res.data.openings;
        this.categorySearchData.push({
          id: res.data.category_id,
          name: res.data.category_name,
        });
        // this.form = res.data;
      });
  },
  computed: {
    ...mapState(["batch"]),
    items() {
      return this.categorySearchData.map((entry) => {
        return { name: entry.name, id: entry.id };
      });
    },
    warehouses() {
      return this.wareHouseSearchData.map((entry) => {
        return { name: entry.name, id: entry.id };
      });
    },
    units() {
      return this.unitsSearchData.map((entry) => {
        return { name: entry.name, id: entry.id };
      });
    },
  },

  watch: {
    // 'pagination': function () {
    //     this.get();
    // },
    // 'batch': function (value) {
    //     this.get();
    // },
    searchC(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get("api/account/inventory/category?search=" + val)
          .then(({ data }) => {
            this.categorySearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchW(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get("api/account/inventory/warehouse?search=" + val)
          .then(({ data }) => {
            this.wareHouseSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchU(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get("api/account/inventory/units?search=" + val)
          .then(({ data }) => {
            this.unitsSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    "addItemData.warehouse_id": function (v) {
      let wData = this.wareHouseSearchData.find((res) => res.id === v);
      this.addItemData.warehouse_name = wData ? wData.name : "";
    },
    "addItemData.unit_id": function (v) {
      let uData = this.unitsSearchData.find((res) => res.id === v);
      this.addItemData.unit_name = uData ? uData.name : "";
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&search=" +
        this.searchAH
      );
    },

    get(params) {
      return "test";
      // let query = [null, undefined].includes(params) ? this.queryString() : params;
      // this.form.get(null, query).then(({data}) => {
      //     this.pagination.totalItems = data.meta.total
      // })
    },
    editData(data) {
      this.categorySearchData = [];
      this.categorySearchData.push({
        name: data.parent_name,
        id: data.parent_id,
      });
    },

    save() {
      if (this.form.is_service_type) this.form.is_service_type = 1;
      else this.form.is_service_type = 0;

      if (this.form.is_taxable) this.form.is_taxable = 1;
      else this.form.is_taxable = 0;
      // this.form.parent_id = this.form.parent_id.id;
      this.form
        .store()
        .then(() => {
          this.form.dialog = false;
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((err) => {
          this.form.dialog = false;
          this.$events.fire("notification", {
            message: err.response.message,
            status: "error",
          });
        });
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        // this.get();
      }, 500);
    },
    addItem(item) {
      if (item.warehouse_id && item.qty && item.rate && item.unit_id) {
        item.warehouse_name = this.findWareHouse(item.warehouse_id).name;
        item.unit_name = this.findUnit(item.unit_id).name;
        this.form.item_data.push(item);
        this.addItemData = {};
      }
    },
    removeItem: function (item) {
      var index = this.form.item_data.indexOf(item);
      this.form.item_data.splice(index, 1);
    },
    editItem(item, index) {
      this.editIndex = index - 1;
      this.addItemData = item;
      this.wareHouseSearchData.push({
        name: item.warehouse_name,
        id: item.warehouse_id,
      });
      this.unitsSearchData.push({ name: item.unit_name, id: item.unit_id });

      this.dialog = true;
    },
    updateItem() {
      // console.log(this.addItemData);
      this.form.item_data[this.editIndex] = this.addItemData;
      this.dialog = false;
      this.addItemData = {};
      // this.form.item_data[this.editIndex].warehouse_name = this.addItemData.warehouse_name
      // this.form.item_data[this.editIndex].warehouse_name = this.addItemData.warehouse_name
      // console.log(this.form.item_data);
      // console.log(this.editIndex);
    },
    findWareHouse(warehouse_id) {
      return this.warehouses.find((res) => res.id === warehouse_id);
    },
    findUnit(unit_id) {
      return this.units.find((res) => res.id === unit_id);
    },
  },
};
</script>
<style lang="scss">
.rowRap .flex.xs6 {
  font-weight: 500;
  font-size: 12px;
}

.itemRap .flex.xs6 {
  margin-top: -35px;
  margin-bottom: -50px;
  font-weight: 500;
  font-size: 12px;
}

.item_form_container {
  margin-top: 20px;
  /*background-color: red;*/
  display: flex;
  .item_form {
    flex: 3;
    padding: 15px;
    border-right: 1px dashed #eee;
    /*background-color: green;*/
    form {
      width: 100%;
    }
  }
  .item_info {
    flex: 1;
    padding: 15px;
    /*background-color: orange;*/
  }
}
</style>

